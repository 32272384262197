<template>
  <div>
    <Header :title="event.name ? event.name : null" :lead="(event.location ? event.location.name : null) + (event.location ? ' - ' + event.location.place : null)" :header="event.header_image ? `https://api.tixgo.nl/files/` + event.header_image : null" />
    <b-container fluid class="header-pull">
      <Stepper :step="step" :steps="steps" />
      <SaleClosedAlert :event="event" />
      <ValidationObserver ref="form1" slim>
        <b-card class="mb-3">
            <EventDetails :event="event" />
          </b-card>
        <b-card class="mb-3" header="Kies je tickets" header-tag="h4" no-body>
          <b-card-body>
            <SlotsTickets :event="event" :form="form" />
          </b-card-body>
          <b-card-footer v-if="Object.keys(event).length">
            <b-row>
              <b-col>
                <b-button :to="backUrl()" variant="outline-secondary">
                  <font-awesome-icon icon="chevron-left" />
                  <template v-if="event.slots.length == 1">
                    Terug
                  </template>
                  <template v-else>
                    Kies datum
                  </template>
              </b-button>
              </b-col>
              <b-col class="text-right">
                <b-button type="button" variant="primary" @click="reserveTickets" :disabled="!hasTickets || isSubmited">
                    Volgende
                    <font-awesome-icon v-if="!isSubmited" icon="chevron-right" />
                    <b-spinner small v-if="isSubmited" class="align-middle" />
                </b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </ValidationObserver>

      <div class="text-center mt-5 mb-5">
        <SocialShare :title="'Uitnodiging voor ' + event.name" :description="'Ik wil supergraag dat je mee gaat naar ' + event.name" :url="getFullUrl" />
      </div>
    </b-container>

    <b-modal ref="error-modal" title="Melding">
      <p>Helaas, de gekozen tickets zijn niet meer beschikbaar. Wijzig het aantal tickets of probeer het op een later moment opnieuw.</p>
      <template #modal-footer="{ close }">
          <b-button variant="primary" @click="close()">OK</b-button>
      </template>
    </b-modal>
    
  </div>
</template>
  
<script>
  import { ValidationObserver } from 'vee-validate';
  
  import Stepper from '@/shared/components/Stepper'
  import EventDetails from '@/shared/components/EventDetails'
  import SocialShare from '@/shared/components/SocialShare'
  import Header from '@/shared/components/Header'
  import SaleClosedAlert from '@/shared/components/SaleClosedAlert'
  import SlotsTickets from '@/shared/components/SlotsTickets'

  export default {
    metaInfo: {
      title: 'Tickets',
    },
    components: {
      ValidationObserver,
      Stepper,
      EventDetails,
      SocialShare,
      Header,
      SaleClosedAlert,
      SlotsTickets,
    },
    data() {
      return {
        step: 1,
        isSubmited: false,
        form: {
          event: null,
          slot: null,
          tickets: [],
        },
        steps: [
          { icon: 'fa-ticket', title: 'Tickets' },
          { icon: 'fa-user', title: 'Informatie' },
          { icon: 'fa-envelope', title: 'Afronden' },
        ],
        event: {},
      }
    },
    computed: {
      getFullUrl() {
        return window.location.href;
      },
      hasTickets() {
        let result = false;
        this.form.tickets.forEach(ticket => {
          if (ticket.amount > 0) {
            result = true;
          }
        });
        return result;
      },
    },
    methods: {
      backUrl: function() {
        if (this.$route.params.host) {
          return '/'+ this.$route.params.host + '/event/' + this.$route.params.event;
        } else {
          return '/event/' + this.$route.params.event;
        }
      },
      reserveTickets: function() {
        if (this.isSubmited) {
          return;
        }

        this.isSubmited = true;

        this.$axios.post('https://api.tixgo.nl/reservation', this.form)
        .then( response => {
            if (response.data) {
              if (response.data.status == 'success') {
                localStorage.setItem('client_id', response.data.client_id);
                localStorage.setItem('client_secret', response.data.client_secret);
                if (this.$route.params.host) {
                  this.$router.push('/' + this.$route.params.host + '/event/' + this.$route.params.event + '/' + this.$route.params.slot + '/checkout');
                } else {
                  this.$router.push('/event/' + this.$route.params.event + '/' + this.$route.params.slot + '/checkout');
                }
              } else {
                this.$refs['error-modal'].show();
                this.getEvent();
                this.isSubmited = false;
              }
            }
        }).catch((reason) => { 
          this.isSubmited = false;
          console.log(reason);
        });
      },
      prepairTicketForm: function() {

        // First reset current
        this.form.tickets = [];

        this.event.tickets.forEach(
          item => {
            let obj = {
              slug_hash: item.slug_hash,
              amount: 0,
            }
            this.form.tickets.push(obj);
          }
        );
      },
      getEvent: function() {
        this.$axios.get('https://api.tixgo.nl/event/' + this.$route.params.event + '?slot=' + this.$route.params.slot)
          .then( response => {
              this.event = response.data;
              this.form.event = this.event.slug_hash;
              this.form.slot = this.event.slot.slug;
              this.prepairTicketForm();
          }
        ).catch((error) => {
          if (error.response.status == '404') { 
            this.$router.push('/404');
          } else {
            this.$router.push('/500');
          }
        });
      },
    },
    created() {
      this.getEvent();
    }
  }
</script>