<template>
    <div class="minusplusnumber" :class="soldout ? 'soldout' : ''">
        <div v-if="!soldout">
            <div class="mpbtn minus" v-on:click="mpminus()">
                -
            </div>
            <div id="field_container">
                <input type="number" v-model="newValue" disabled />
            </div>
            <div class="mpbtn plus" v-on:click="mpplus()">
                +
            </div>
        </div>
        <div v-else>
            Uitverkocht
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            default: 0,
            type: Number
        },
        min: {
            default: 0,
            type: [ Number, String ]
        },
        max: {
            default: undefined,
            type: [ Number, String ]
        },
        soldout: {
            default: false,
            type: [ Boolean ]
        }
    },
    data () {
        return {
            newValue: 0
        }
    },
    methods: {
        getNotificationClass (notification) {
            return `alert alert-${notification.type}`
        },
        mpplus: function () {
            if (this.max === undefined || (this.newValue < this.max)) {
                this.newValue = this.newValue + 1
                this.$emit('input', this.newValue)
                this.$emit('change', this.newValue)
                this.$emit('plus', this.newValue)
            }
        },
        mpminus: function () {
            if ((this.newValue) > this.min) {
                this.newValue = this.newValue - 1
                this.$emit('input', this.newValue)
                this.$emit('change', this.newValue)
                this.$emit('minus', this.newValue)
            }
        }
    },
    watch: {
        value: {
            handler: function (newVal) {
                this.newValue = newVal
            }
        }
    },
    created: function () {
        this.newValue = this.value
    }
}
</script>
<style scoped>
.minusplusnumber {
    border:1px solid silver;
    border-radius:5px;
    background-color: #FFF;
    display:inline-block;
    user-select: none;
}
.minusplusnumber.soldout{
    border:1px solid #000;
    background-color:#000;
    padding:5px 10px;
    color:#fff;
}
.minusplusnumber div {
    display:inline-block;
}
.minusplusnumber #field_container input {
    width:50px;
    text-align:center;
    font-size:15px;
    padding:3px;
    border:none;
}
.minusplusnumber .mpbtn {
    padding:3px 10px 3px 10px;
    cursor:pointer;
    border-radius:5px;
}
.minusplusnumber .mpbtn:hover {
    background-color:#DDD;
}
.minusplusnumber .mpbtn:active {
    background-color:#c5c5c5;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
input[type=number] {
-moz-appearance: textfield;
}
</style>
