<template>
    <div>
        <template v-if="!Object.keys(event).length">
            <b-row class="pt-2 pb-2 border-bottom" align-v="center">
                <b-col>
                    <b-skeleton animation="wave" width="150px" class="mb-2" />
                    <b-skeleton animation="wave" width="100%" class="mb-2" />
                </b-col>
                <b-col>
                    <b-row class="pt-2 pb-2" align-v="center">
                        <b-col md="6">
                            <b-skeleton animation="wave" width="50%" class="mb-2  ml-auto" />
                            <b-skeleton animation="wave" width="80%" class="mb-2  ml-auto" />
                        </b-col>
                        <b-col md="6">
                            <b-skeleton class="mt-2 ml-auto" type="button" width="100px" />
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <div class="mt-3">
                <b-skeleton animation="wave" width="40%" class="mb-2 ml-auto" />
            </div>
        </template>
        <template v-else>
            <b-row align-v="center" v-for="(ticket, key) in event.tickets" :key="key" class="border-bottom pt-2 pb-2">
                <b-col>
                    <h4 class="mb-0 fs-5">{{ ticket.name }}</h4>
                    <p class="text-muted mb-0">
                        {{ event.slot.start_at|formatTextDate }} om {{ event.slot.start_at|formatTime }} uur
                    </p>
                    <p v-if="ticket.description" class="text-muted mb-0">
                        {{ ticket.description }}
                    </p>
                </b-col>
                <b-col class="text-right">
                    <b-row align-v="center">
                        <b-col md="6">
                            <div class="mb-2 mb-md-0">
                                <div class="fs-5">
                                    <template v-if="ticket.price == 0">
                                        GRATIS
                                    </template>
                                    <template v-else>
                                        {{ ticket.price|toCurrency }}
                                    </template>
                                </div>
                                <template v-if="event.is_customer_ticket_fee">
                                    <template v-if="event.customer_ticket_fee > 0">
                                        <p class="text-muted mb-0">Excl. {{ event.customer_ticket_fee|toCurrency }} service fee</p>
                                    </template>
                                </template>
                            </div>
                        </b-col>
                        <b-col md="6">
                            <template v-if="event.canceled_at">
                                <b-button variant="danger" :disabled="true">
                                    Geannuleerd
                                </b-button>
                            </template>
                            <template v-else-if="event.is_end_ticket_sale == false && event.is_start_ticket_sale == true">
                                <b-badge v-if="!ticket.is_sold_out && ticket.is_nearly_sold_out" variant="primary" class="mr-2">
                                    Nog enkele tickets beschikbaar
                                </b-badge>
                                <Plusminus :value="getTicketCount(ticket)" class="mt-2" @plus="addTicket(ticket)" @minus="removeTicket(ticket)" :min="0" :max="ticket.order_max" :soldout="ticket.is_sold_out" />
                            </template>
                            <template v-else-if="event.is_end_ticket_sale == false && event.is_start_ticket_sale == false">
                                <b-button variant="dark" :disabled="true">
                                    Nog niet beschikbaar
                                </b-button>
                            </template>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <div class="mt-3 text-right">
                <div class="fs-5">Totaal: {{ calcTotalPrice|toCurrency }}</div>
                <template v-if="event.is_customer_transaction_fee && calcTotalPrice > 0">
                    <p class="text-muted mb-0">Exclusief transactiekosten van minimaal &euro; 0,35</p>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
    import Plusminus from '@/shared/components/Plusminus'

    export default {
        components: {
            Plusminus
        },
        props: {
            event: {
                default: null,
                type: Object
            },
            form: {
                default: null,
                type: Object
            },
        },
        data() {
            return {}
        },
        computed: {
            calcTotalPrice() {
                let count = 0;
                this.form.tickets.forEach(ticket => {
                    this.event.tickets.forEach(item => {
                        if (item.slug_hash == ticket.slug_hash) {
                            count += ticket.amount * this.calcTicketPrice(item);
                        }
                    });
                });
                return count;
            },
            calcTicketPrice() {
                return function(ticket) {
                    return Number(ticket.price) + Number(this.event.customer_ticket_fee);
                }
            },
        },
        methods: {
            addTicket: function(ticket) {
                this.form.tickets.forEach(
                    function(item) {
                        if (item.slug_hash == ticket.slug_hash) {
                            item.amount++;
                        }
                    }
                );
            },
            removeTicket: function(ticket) {
                this.form.tickets.forEach(
                    function(item) {
                        if (item.slug_hash == ticket.slug_hash) {
                            item.amount--;
                        }
                    }
                );
            },
            getTicketCount: function(ticket) {
                let count = 0;
                this.form.tickets.forEach(
                    item => {
                        if (item.slug_hash == ticket.slug_hash) {
                            count = item.amount;
                        }
                    }
                );
                return count;
            },
        },
    }
</script>